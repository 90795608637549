<template>
    <v-app>
        <v-row>
            <v-col cols="12" md="9" lg="9" sm="12">
                <v-stepper v-model="e1">
                    <v-stepper-header>
                        <v-stepper-step :complete="e1 > 1" step="1">Basic Info</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="e1 > 2" step="2">Address</v-stepper-step>

                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 3" step="3">Services</v-stepper-step>

                        <v-divider></v-divider>
                        <v-stepper-step :complete="e1 > 4" step="4">Additional Info</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="5">Submission</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-form v-model="valid" ref="organisation" lazy-validation autocomplete="off">
                                <v-container class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="organisation.title"
                                                :error-messages="nameErrors"
                                                label="Business Name *"
                                                required
                                                persistent-hint
                                                @blur="$v.organisation.title.$touch()"
                                                @input="validateOrganisationName"
                                                hint="Eg: Yelko Web Pvt. Ltd."
                                                outlined
                                                dense
                                                autocomplete="off"
                                                class="mb-3"
                                            ></v-text-field>
                                            <p
                                                v-if="!organisation_name_unique"
                                                class="text-danger font-weight-bold"
                                            >This business name is already registered with us.</p>
                                            <v-text-field
                                                v-model="organisation.tag_line"
                                                label="Business tagline or slogan"
                                                required
                                                persistent-hint
                                                hint="Eg: Powering Business, Informations & Websites"
                                                outlined
                                                dense
                                                class="mb-3"
                                            ></v-text-field>
                                            <ckeditor v-model="organisation.description"
                                                      :config="editorConfig"></ckeditor>
                                            <p
                                                v-if="descriptionErrors[0]"
                                                class="text-danger font-weight-bold"
                                            >{{descriptionErrors[0]}}</p>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="organisation.phone"
                                                label="Phone *"
                                                :error-messages="phoneErrors"
                                                @blur="$v.organisation.phone.$touch()"
                                                persistent-hint
                                                hint="Eg: 9823428000 or 01-4358xxx"
                                                counter="10"
                                                prepend-inner-icon="phone"
                                                required
                                                outlined
                                                dense
                                                class="mb-3"
                                            ></v-text-field>

                                            <v-text-field
                                                v-model="organisation.mobile"
                                                label="Mobile "
                                                :error-messages="mobileErrors"
                                                @blur="$v.organisation.mobile.$touch()"
                                                persistent-hint
                                                hint="Eg: 9823428000 or 01-4358xxx"
                                                prepend-inner-icon="smartphone"
                                                counter="10"
                                                required
                                                outlined
                                                dense
                                                class="mb-3"
                                            ></v-text-field>

                                            <v-text-field
                                                v-model="organisation.email"
                                                :error-messages="emailErrors"
                                                @blur="$v.organisation.email.$touch()"
                                                label="Primary  Email"
                                                persistent-hint
                                                hint="Eg: contact@yelko.com"
                                                prepend-inner-icon="email"
                                                required
                                                outlined
                                                dense
                                                class="mb-3"
                                            ></v-text-field>

                                            <v-text-field
                                                v-model="organisation.alt_email"
                                                :error-messages="altEmailErrors"
                                                @blur="$v.organisation.alt_email.$touch()"
                                                label="Secondary Email"
                                                persistent-hint
                                                hint="Eg: support@yelko.com"
                                                prepend-inner-icon="email"
                                                required
                                                outlined
                                                dense
                                                class="mb-3"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="organisation.website"
                                                label
                                                persistent-hint
                                                hint="Eg: www.yelko.com"
                                                prepend-inner-icon="web"
                                                prefix="www."
                                                required
                                                outlined
                                                dense
                                                class="mb-3"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="organisation.alt_website"
                                                label
                                                persistent-hint
                                                hint="Eg: www.yelko.net"
                                                prepend-inner-icon="web"
                                                required
                                                outlined
                                                prefix="www."
                                                dense
                                                class="mb-3"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-col cols="12" md="12">
                                <div class="text-right">
                                    <v-btn
                                        color="primary"
                                        :disabled="!organisation_name_unique"
                                        @click="validOgranisation"
                                    >
                                        Next
                                        <i class="fa fa-arrow-alt-circle-right"></i>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-form v-model="valid" ref="address" lazy-validation>
                                <v-container class="form-block">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="model"
                                                :items="items"
                                                :loading="isLoading"
                                                :search-input.sync="search"
                                                hide-no-data
                                                hide-selected
                                                item-text="Description"
                                                item-value="area"
                                                @change="placechange"
                                                label="Find Your Place"
                                                placeholder="Start typing to Search your place"
                                                prepend-inner-icon="mdi-database-search"
                                                return-object
                                                outlined
                                                disableBrowserAutocomplete
                                                autocomplete="off"
                                                clear-icon
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-text-field
                                                label="Address Line1"
                                                v-model="address.address_line_1"
                                                required
                                                hint=""
                                                persistent-hint
                                                outlined
                                                autocomplete="off"
                                                clear-icon
                                                dense
                                            ></v-text-field>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.address.address_line_1.$error"
                                            >{{validationMessage.address.address_line_1}}</p>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-text-field
                                                label="Address Line 2"
                                                v-model="address.address_line_2"
                                                hint="Eg: Shantinagar or Pahiko Tole"
                                                persistent-hint
                                                outlined
                                                autocomplete="off"
                                                clear-icon
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!-- <v-col cols="2" md="2">
                                        <v-text-field label="Post Code" v-model="address.post_code" required></v-text-field>
                                        </v-col>-->
                                        <v-col cols="6" md="6">
                                            <v-combobox
                                                v-model="address.area"
                                                :items="areas"
                                                hide-selected
                                                label="Area"
                                                hint="Eg: Baneswhor, Kalanki"
                                                persistent-hint
                                                outlined
                                                autocomplete="off"
                                                clear-icon
                                                dense
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                No results matching "
                                                                <strong>{{ search }}</strong>". Press
                                                                <kbd>enter</kbd> to create a new one
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.address.area.$error"
                                            >{{validationMessage.address.area}}</p>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-combobox
                                                v-model="address.city"
                                                :items="cities"
                                                hide-selected
                                                label="City"
                                                hint="Eg: Kathmandu, Pokhara"
                                                persistent-hint
                                                outlined
                                                autocomplete="off"
                                                clear-icon
                                                dense
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                No results matching "
                                                                <strong>{{ search }}</strong>". Press
                                                                <kbd>enter</kbd> to create a new one
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.address.city.$error"
                                            >{{validationMessage.address.city}}</p>
                                        </v-col>
                                        <v-col cols="6" md="6">
                                            <v-combobox
                                                v-model="address.state"
                                                :items="states"
                                                hide-selected
                                                label="State"
                                                autocomplete="off"
                                                hint="Eg: Bagmati Pradesh"
                                                persistent-hint
                                                outlined
                                                clear-icon
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                No results matching "
                                                                <strong>{{ search }}</strong>". Press
                                                                <kbd>enter</kbd> to create a new one
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.address.state.$error"
                                            >{{validationMessage.address.state}}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <!-- <v-col cols="6" md="6">
                                        <v-combobox
                                          v-model="address.country_id"
                                          :items="countries"
                                          hide-selected
                                          label="Country"
                                          persistent-hint
                                        >
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title>
                                                  No results matching "
                                                  <strong>{{ search }}</strong>". Press
                                                  <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </template>
                                        </v-combobox>
                                        </v-col>-->

                                        <v-col cols="12" md="12">
                                            <v-checkbox
                                                v-model="showMaps"
                                                label="Do you want to display your business on map ? "
                                            ></v-checkbox>
                                        </v-col>

                                        <v-col cols="12" md="12" v-if="showMaps">
                                            <GmapMap
                                                style="width: 100%; height: 250px;"
                                                :zoom="8"
                                                map-type-id="roadmap"
                                                :center="center"
                                            >
                                                <GmapMarker
                                                    :position="position"
                                                    :clickable="true"
                                                    :draggable="true"
                                                    v-on:dragend="changePosition($event)"
                                                    @click="center=position"
                                                />
                                            </GmapMap>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-col cols="12" md="12">
                                <v-btn @click="e1 = 1" class="mr-3 pull-left">
                                    <i class="fa fa-arrow-alt-circle-left"></i> Previous
                                </v-btn>
                                <v-btn color="primary" @click="validateAddress" class="pull-right">
                                    Next
                                    <i class="fa fa-arrow-alt-circle-right"></i>
                                </v-btn>
                            </v-col>
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <v-form v-model="valid" ref="service" lazy-validation>
                                <v-container class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h3>What are the services provided by your business ?</h3>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-combobox
                                                v-model="servicesValue"
                                                :items="serviceItems"
                                                hide-selected
                                                label="Add Services"
                                                multiple
                                                item-text="title"
                                                item-value="id"
                                                persistent-hint
                                                small-chips
                                                outlined
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                No results matching "
                                                                <strong></strong>". Press
                                                                <kbd>enter</kbd> to create a new one
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-combobox
                                                v-model="tags"
                                                :items="tagList"
                                                hide-selected
                                                hint="Maximum of 5 tags"
                                                label="Add some tags/keywords"
                                                multiple
                                                return-object
                                                persistent-hint
                                                small-chips
                                                outlined
                                                autocomplete="off"
                                            >
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                Press
                                                                <kbd>enter</kbd> to create a new one
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h3>Which category your business falls into ?</h3>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                :items="categories"
                                                v-model="category"
                                                @change=" changeSubCatergory(`${category.id}`)"
                                                item-text="title"
                                                item-value="id"
                                                return-object
                                                label="Category"
                                                persistent-hint
                                                outlined
                                                autocomplete="off"
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-select
                                                v-model="newCategory.directory_sub_category_id"
                                                :items="subcatergories"
                                                item-text="title"
                                                item-value="id"
                                                label="Sub Category"
                                                outlined
                                                autocomplete="off"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-col cols="12" md="12">
                                <v-btn @click="e1 = 2" class="mr-3 pull-left">
                                    <i class="fa fa-arrow-alt-circle-left"></i> Previous
                                </v-btn>
                                <v-btn color="primary" @click="addService" class="pull-right">
                                    Next
                                    <i class="fa fa-arrow-alt-circle-right"></i>
                                </v-btn>
                            </v-col>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                            <v-form v-model="valid">
                                <v-container class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-file-input
                                                type="file"
                                                ref="file"
                                                @change="uploadImage($event)"
                                                accept="image/*"
                                                placeholder="Upload business logo"
                                                prepend-inner-icon="mdi-camera"
                                                label="Logo"
                                                outlined
                                            ></v-file-input>
                                            <v-select
                                                :items="employee_size"
                                                v-model="attributes.employee_size"
                                                label="Employee Size"
                                                prepend-inner-icon="person"
                                                outlined
                                            ></v-select>

                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                                outlined
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="attributes.established_year"
                                                        label="Establishment Year"
                                                        prepend-inner-icon="event"
                                                        readonly
                                                        v-on="on"
                                                        outlined
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    ref="picker"
                                                    v-model="attributes.established_year"
                                                    @change="save"
                                                    :max="getEndDate"
                                                    type="month"
                                                    outlined
                                                ></v-date-picker>
                                            </v-menu>
                                            <v-switch
                                                v-model="attributes.booking_enabled"
                                                label="Would you like to accept online booking or inquires? "
                                            ></v-switch>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <!-- <v-switch v-model="attributes.has_opening_hours" label="Opening Hours"></v-switch> -->
                                            <Cropper
                                                class="upload-example-cropper"
                                                :src="image"
                                                :stencil-props="{

                              previewClassname: 'preview',
                            }"
                                                :imageRestriction="imageRestriction"
                                                :backgroundClassname="'white-background'"
                                                @change="change"
                                            />
                                            Recommended size: 300 x 300 pixel
                                            <br/>Minimum Resolution: 72
                                            <!-- <v-switch v-model="attributes.has_branch" label="Branch "></v-switch> -->
                                            <!-- <v-switch v-model="attributes.has_featured_video" label="Featured Video "></v-switch> -->
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                            <v-col cols="12" md="12">
                                <v-btn @click="e1 = 3" class="mr-3 pull-left">
                                    <i class="fa fa-arrow-alt-circle-left"></i> Previous
                                </v-btn>
                                <v-btn color="primary" @click="e1 = 5" class="pull-right">
                                    Next
                                    <i class="fa fa-arrow-alt-circle-right"></i>
                                </v-btn>
                            </v-col>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                            <v-col cols="12" md="12">
                                <h5 class="mb-10">You business information is now ready to be submit.</h5>

                                <h6>Verify your information on the preview and then click submit to complete your
                                    registration.</h6>
                                <v-col cols="12" md="12">
                                    <v-btn @click="e1 = 4" class="mr-3 pull-left">
                                        <i class="fa fa-arrow-alt-circle-left"></i> Previous
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class="mb-5 mr-5"
                                        @click.prevent="submitData"
                                    >Submit for Registration
                                    </v-btn>&nbsp;
                                </v-col>
                            </v-col>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
            <v-col cols="12" md="3" lg="3" sm="12">
                <v-card>
                    <v-card-title v-if="organisation.title">Preview your listing</v-card-title>
                    <v-card-title v-else>Preview</v-card-title>
                    <v-card-text v-if="organisation.title">
                        <div class="col-lg-12 text-center">
                            <div class="brand-wrap">
                                <v-list-item-avatar tile size="200" class="border pa" color="grey">
                                    <v-img :src="attributes.logo_image"></v-img>
                                </v-list-item-avatar>
                            </div>
                        </div>
                        <h4 class="text-center">{{organisation.title}}</h4>
                        <h6 class="text-center mb-5" v-if="organisation.tag_line">{{organisation.tag_line}}</h6>
                        <h6 class="text-center mb-5" v-if="organisation.description">
                            <i class="fa fa-info-circle"></i>
                            <span v-html="organisation.description"></span>
                        </h6>
                        <h6 class="text-center mb-5" v-if="organisation.phone">
                            <i class="fa fa-phone-alt"></i>
                            {{organisation.phone}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="organisation.mobile">
                            <i class="fa fa-mobile-alt"></i>
                            {{organisation.mobile}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="organisation.email">
                            <i class="fa fa-envelope"></i>
                            {{organisation.email}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="organisation.alt_email">
                            <i class="fa fa-envelope-open"></i>
                            {{organisation.alt_email}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="organisation.website">
                            <i class="fa fa-globe"></i>
                            {{organisation.website}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="organisation.alt_website">
                            <i class="fa fa-globe"></i>
                            {{organisation.alt_website}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="address.address_line_1">
                            <i class="fa fa-map"></i>
                            <br/>
                            {{address.address_line_1}},
                            <span v-if="address.address_line_2">{{ address.address_line_2 }},</span>
                            <span v-if="address.area">{{ address.area }},</span>
                            <span v-if="address.city">{{ address.city }},</span>
                            <span v-if="address.state">{{ address.state }},</span>
                            <span v-if="address.province">{{ address.province }}</span>
                        </h6>
                        <h6 class="text-center mb-5" v-if="attributes.established_year">
                            <i class="fa fa-calendar"></i>
                            {{attributes.established_year}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="attributes.employee_size">
                            <i class="fa fa-users"></i>
                            {{attributes.employee_size}}
                        </h6>
                        <h6 class="text-center mb-5" v-if="category.title">
                            <i class="fa fa-users"></i>
                            {{category.title}}
                        </h6>
                        <h6 class="text-center mb-5">
                            <i
                                v-if="attributes.booking_enabled"
                                class="flaticon2-checkmark text-sucess"
                            >Booking Enabled</i>
                            <i v-else class="flaticon-close">Booking Disabled</i>
                        </h6>
                        <h6 v-if="organisation.booking_enabled">{{organisation.booking_enabled}}</h6>
                    </v-card-text>
                    <v-card-text v-else>
                        Start Adding your business information
                        <div class="col-lg-12 text-center">
                            <div class="brand-wrap">
                                <v-list-item-avatar tile size="100%" class="border pa" color="grey">
                                    <v-img :src="attributes.logo_image"></v-img>
                                </v-list-item-avatar>
                            </div>
                        </div>
                        <h4 class="text-center">Company name</h4>
                        <h6 class="text-center mb-5">Slogan</h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-info-circle"></i> Write short description about your business
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-phone-alt"></i> Phone
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-mobile-alt"></i> Mobile
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-envelope"></i> Email
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-globe"></i>Website
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-map"></i>
                            <br/>Your business address
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-calendar"></i> Established year
                        </h6>
                        <h6 class="text-center mb-5">
                            <i class="fa fa-users"></i>10-20 employee
                        </h6>
                        <h6 v-if="organisation.booking_enabled">{{organisation.booking_enabled}}</h6>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- <pre>{{address}}</pre> -->
        </v-row>
    </v-app>
</template>


<script>
    import {mapState} from "vuex";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {Cropper} from "vue-advanced-cropper";
    import {validationMixin} from "vuelidate";
    import {
        email,
        required,
        minLength,
        sameAs,
        url
    } from "vuelidate/lib/validators";
    import ApiService from "@/common/api.service";
    import NotifyService from "@/common/notify.service";
    import OgranisationService from "@/services/organisation/OrganisationService";
    import OgranisationAddressService from "@/services/organisation/OrganisationAddressService";
    import OgranisationAttributeService from "@/services/organisation/OrganisationAttributeService";
    import OgranisationCategoryService from "@/services/organisation/OrgnisationCategoryService";
    import OgranisationServ from "@/services/organisation/OrganisationServiceService";
    import CKEditor from "ckeditor4-vue";
    import OrganisationAddressService from "@/services/organisation/OrganisationAddressService";
    import DirectoryTagService from "@/services/directory/DirectoryTagService";
    import {isPhoneNumner, isNullableMobileNumber} from "@/common/validation";


    const ogranisation = new OgranisationService();
    const ogranisationAddress = new OgranisationAddressService();
    const ogranisationAttribute = new OgranisationAttributeService();
    const ogranisationCategory = new OgranisationCategoryService();
    const ogranisationServices = new OgranisationServ();
    const directoryService = new DirectoryTagService();
    const organisationAddressService = new OrganisationAddressService();


    export default {
        name: "OrganisationAdd",
        mixins: [validationMixin],
        validations: {
            organisation: {
                title: {
                    required
                },
                description: {required},
                phone: {required, isPhoneNumner},
                email: {required, email},
                mobile: {isNullableMobileNumber},
                alt_email: {email}
            },
            address: {
                address_line_1: {required},
                city: {required},
                area: {required},
                state: {required}
            }
        },

        data: function () {
            return {
                organisation_name_unique: true,
                newDate: new Date(),
                tagList: [],
                e1: 1,
                valid: false,
                loggedUserId: null,
                entries: [],
                isLoading: false,
                showMaps: false,
                menu: false,
                editorConfig: {
                    toolbar: [["Bold", "Italic", "Underline"]]
                },
                employee_size: ["1-5", "5-15", "15-30", "30-50", "50-150"],
                search: null,
                searchtags: null,
                center: {lat: 28.3949, lng: 84.124},
                categories: [],
                subcatergories: [],
                serviceItems: [],
                cities: [],
                areas: [],
                states: [],
                countries: [],

                model: "",

                position: {lat: 28.253342268986668, lng: 84.93813695937502},
                markers: [
                    {
                        position: {lat: 27.6711212, lng: 85.3446311}
                    }
                ],
                place: null,
                validationMessage: {
                    organisation: {
                        title: "Title is Required",
                        description: "Description is required",
                        phone: "phone must be valid",
                        email: "email must be valid"
                    },
                    address: {
                        address_line_1: "Address Line is required",
                        city: "City is required",
                        area: "Area is required",
                        state: "State is required"
                    }
                },
                organisation: {
                    title: "",
                    tag_line: "",
                    description: "",
                    mobile: "",
                    alt_phone: "",
                    email: "",
                    alt_email: "",
                    website: "",
                    alt_website: "",
                    owner_user_id: "",
                    is_active: true
                },

                attributes: {
                    organisation_id: 11,
                    type: "",
                    established_year: "",
                    employee_size: "",
                    logo_image: "",
                    has_opening_hours: "",
                    booking_enabled: "",
                    has_branch: "",
                    has_featured_video: ""
                },
                servicesValue: [],
                newservices: [],
                category: {},
                subcategory: {},
                newCategory: {
                    title: "",
                    directory_category_id: null,
                    directory_sub_category_id: null
                },
                address: {
                    organisation_id: "",
                    address_line_1: "",
                    address_line_2: "",
                    post_code: "",
                    city: "",
                    area: "",
                    state: "",
                    province: "",
                    country_id: "",
                    geolocation_id: "",
                    geo_lat: "",
                    geo_long: "",
                    is_primary: 1
                },
                date: "",
                imageRestriction: "none",
                dialog: false,
                image: "/assets/media/logos/icon-no-image.svg",
                tags: [],
                data: {
                    organisationDetail: {
                        organisation: {
                            title: "yelko",
                            tag_line: "",
                            description: "",
                            mobile: "",
                            phone: "",
                            alt_phone: "",
                            email: "",
                            alt_email: "",
                            website: "",
                            alt_website: "",
                            owner_user_id: ""
                        },

                        attributes: {
                            organisation_id: "",
                            type: "",
                            established_year: "",
                            employee_size: "",
                            logo_image: "",
                            cover_image: "",
                            facebook: "",
                            youtube: "",
                            instagram: "",
                            twitter: "",
                            linkedin: "",
                            pinterest: "",
                            vimeo: "",
                            has_opening_hours: "",
                            booking_enabled: "",
                            has_branch: "",
                            has_featured_video: "",
                            featured_video_url: "",
                            featured_link_redirect: ""
                        },

                        services: {
                            organisation_id: "",
                            title: "",
                            directory_service_id: "",
                            sort_position: "",
                            is_active: ""
                        }
                    }
                }
            };
        },
        components: {
            Cropper,
            ckeditor: CKEditor.component
        },
        created() {
            this.organisation.owner_user_id = this.$store.getters.currentUser.id;
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: " organization", route: "/organizations"},
                {title: "New Organization"}
            ]);

            this.getAllAddress();
            this.getTags();
            ogranisationCategory
                .getAllCategory()
                .then(res => {
                    this.categories = res.data.data;
                })
                .catch(err => {
                });

            ogranisationServices
                .getServices()
                .then(res => {
                    this.serviceItems = res.data.data;
                })
                .catch(err => {
                });
        },
        computed: {
            coordinates() {
                return this.position;
            },
            nameErrors() {
                const errors = [];
                if (!this.$v.organisation.title.$dirty) return errors;
                !this.$v.organisation.title.required &&
                errors.push("Name is required and not registered with us");
                return errors;
            },
            descriptionErrors() {
                const errors = [];
                if (!this.$v.organisation.description.$dirty) return errors;
                !this.$v.organisation.description.required &&
                errors.push("Description is required.");
                return errors;
            },
            emailErrors() {
                const errors = [];
                if (!this.$v.organisation.email.$dirty) return errors;
                !this.$v.organisation.email.email && errors.push("Must be valid e-mail");
                !this.$v.organisation.email.required && errors.push("E-mail is required");
                return errors;
            },
            altEmailErrors() {
                const errors = [];
                if (!this.$v.organisation.alt_email.$dirty) return errors;
                !this.$v.organisation.alt_email.email &&
                errors.push("Must be valid e-mail");
                return errors;
            },
            phoneErrors() {
                const errors = [];
                if (!this.$v.organisation.phone.$dirty) return errors;
                !this.$v.organisation.phone.required && errors.push("Phone is reuqired");
                !this.$v.organisation.phone.isPhoneNumner &&
                errors.push("Phone must be valid");
                return errors;
            },
            mobileErrors() {
                const errors = [];
                if (!this.$v.organisation.mobile.$dirty) return errors;
                !this.$v.organisation.mobile.isNullableMobileNumber &&
                errors.push("Mobile No must be valid and 10 Digit");
                return errors;
            },

            fields() {
                if (!this.model) return [];

                return Object.keys(this.model).map(key => {
                    return {
                        key,
                        value: this.model[key] || "n/a"
                    };
                });
            },
            items() {
                return this.entries.map((entry, index) => {
                    const Description = entry.area + ", " + entry.city + ", " + entry.state;
                    return Object.assign({}, entry, {Description});
                });
            },
            getEndDate() {
                var endDate = new Date(
                    this.newDate.getFullYear(),
                    this.newDate.getMonth()
                );
                return endDate.toISOString().slice(0, 10);
            }
        },

        methods: {
            validOgranisation() {
                this.validateOrganisationName();
                this.$v.$touch();
                if (!this.$v.organisation.$error) {
                    this.e1 = 2;
                }
            },
            validateAddress() {
                this.$v.$touch();
                if (this.$v.address.$error) {
                } else {
                    this.e1 = 3;
                }
            },
            getAllAddress() {
                organisationAddressService.getAllAddresses().then(res => {
                    this.areas = res.data.areas;
                    this.cities = res.data.cities;
                    this.states = res.data.states;
                });
            },
            submitData() {
                let object = {
                    organisation: this.organisation,
                    address: this.address,
                    attribute: this.attributes,
                    services: this.newservices,
                    tags: this.tags,
                    category: this.newCategory
                };
                if (!this.$v.$error) {
                    ogranisation
                        .createOrganisationFromStepper(object)
                        .then(res => {
                            this.$router.push({
                                name: "organization-profile",
                                params: {name: res.data.result.slug}
                            });
                            this.$snotify.success("Business information updated ");
                        })
                        .catch(err => {
                        });
                }
            },

            changeSubCatergory(cat) {
                this.newCategory.title = this.category.title;
                this.newCategory.directory_category_id = cat;
                ogranisationCategory
                    .getSubCategory(cat)
                    .then(res => {
                        this.subcatergories = res.data.data;
                    })
                    .catch(err => {
                    });
            },

            setPlace(place) {
                this.place = place;
            },
            changePosition(e) {
                this.position.lat = e.latLng.lat();
                this.position.lng = e.latLng.lng();
                this.address.geo_lat = e.latLng.lat();
                this.address.geo_long = e.latLng.lng();
                this.center = this.position;
            },
            usePlace(place) {
                if (this.place) {
                    this.position.lat = this.place.geometry.location.lat();
                    this.position.lng = this.place.geometry.location.lng();
                    this.address.geo_lat = this.place.geometry.location.lat();
                    this.address.geo_long = this.place.geometry.location.lng();
                    this.center = this.position;
                }
                this.place = null;
            },
            isObject(arg) {
                return arg !== null && typeof arg === "object";
            },
            addService() {
                this.e1 = 4;
                this.servicesValue.filter((value, index) => {
                    if (this.isObject(value)) {
                        this.newservices.push({
                            title: value.title,
                            directory_service_id: value.id,
                            sort_position: 1,
                            is_active: 1
                        });
                    } else {
                        this.newservices.push({
                            title: value,
                            directory_service_id: null,
                            sort_position: 1,
                            is_active: 1
                        });
                    }
                });
            },
            placechange() {
                this.address.post_code = this.model.post_code;
                this.address.city = this.model.city;
                this.address.area = this.model.area;
                this.address.state = this.model.state;
                this.address.province = this.model.province;
                this.address.geolocation_id = this.model.geolocation_id;
                this.address.geo_lat = parseFloat(this.model.geo_lat);
                this.address.geo_long = parseFloat(this.model.geo_long);
                this.position.lat = parseFloat(this.model.geo_lat);
                this.position.lng = parseFloat(this.model.geo_long);
                this.center = this.position;
                this.address.geolocation_id = this.model.id;
            },

            change({coordinates, canvas}) {
                this.data.organisationDetail.attributes.logo_image = canvas.toDataURL(
                    "image/jpeg"
                );
                this.attributes.logo_image = canvas.toDataURL("image/jpeg");
                //
            },
            uploadImage(event) {
                if (event) {
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = e => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(event);
                }
            },
            save(date) {
                this.$refs.menu.save(date);
            },

            validateOrganisationName() {
                let object = {name: this.organisation.title};
                var status = null;
                ApiService.post("user/organisation/validate-name", object)
                    .then(response => {
                        this.organisation_name_unique = response.data.status;
                    })
                    .catch(error => {
                        return false;
                    });
            }, getTags() {
                directoryService.getSuggestionList().then(response => {
                    this.tagList = response.data
                }).catch(error => {
                })
            }
        },
        watch: {
            search(val) {
                // Items have already been loaded
                if (this.items.length > 0) return;

                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                // fetch("https://api.publicapis.org/entries")
                organisationAddressService
                    .getAddress(val)
                    .then(res => {
                        const {count, result} = res.data;
                        this.count = count;
                        this.entries = result;
                    })
                    .catch(err => {
                    })
                    .finally(() => (this.isLoading = false));
            }
        }
    };
</script>
<style>
    .cropper {
        height: 400px;
        background: #ddd;
    }

    .upload-example-cropper {
        border: solid 1px #eee;
        height: 250px;
        width: 100%;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }

    .button {
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        background: #3fb37f;
        cursor: pointer;
        transition: background 0.5s;
    }

    .button:hover {
        background: #38d890;
    }

    .button input {
        display: none;
    }

    .v-application--wrap {
        min-height: auto !important;
    }

    .maps-autocomplete {
        width: 100%;
        border-bottom: 1px solid #3899ec;
    }

    .maps-autocomplete:hover,
    .maps-autocomplete:focus {
        outline: none;
    }

    .preview {
        border: solid 1px green;
    }

    .white-background {
        background-color: white !important;
        border: solid 1px #eee;
    }

    .upload-example-cropper {
        border: solid 1px #eee;
        height: 250px;
        width: 100%;
    }

    .vue-square-handler {
        background: turquoise !important;
    }
</style>
