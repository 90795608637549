import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class organisationCategory {
    getAllCategory(){
        let url = API_URL + 'user/directory-category/parent/all' ;
        return apiService.get(url);

    }
    getSubCategory(categoryId){
        let url = API_URL + 'user/directory-category/parent/' + categoryId  + '/child' ;
        return apiService.get(url);
    }

    paginate(slug,index = null) {
        let url = API_URL + 'user/organisation/' + slug + '/attribute';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(slug,data) {
        let url = API_URL + 'user/organisation/' + slug + '/attribute'
        return apiService.post(url, data);
    }

    update(slug, id , data) {
        let url = API_URL + 'user/organisation/'+ slug + '/attribute/'  + id + '/id'
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/attribute/' + id + '/delete'
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/attribute/' + id
        return apiService.get(url);
    }


}
